<template>
  <v-container class="py-0">
    <v-row>
      <v-list v-if="showtemplateDetails" class="pa-0">
        <template v-for="(item, index) in templateDetails">
          <v-list-item
            :key="index"
            class="py-0"
            two-line
            @click="clickItem(item)"
          >
            <v-list-item-content class="py-0">
              <v-list-item-title class="py-0">{{
                item.templateName
              }}</v-list-item-title>
              <v-list-item-subtitle class="py-0">{{
                item.templateDescription
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <span
        v-if="!showtemplateDetails && loading"
        class="subtitle-1 mb-4 blue--text"
        >{{ $t("template_details_loading") }}</span
      >
      <span
        v-if="!showtemplateDetails && !loading"
        class="subtitle-1 mb-4 red--text"
        >{{ $t("template_details_issue") }}</span
      >
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs";

const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsLinkedTemplates",
  props: {
    fileId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showtemplateDetails: false,
      loading: true,
      templateDetails: []
    };
  },
  watch: {
    fileId: {
      deep: true,
      immediate: true,
      handler(value) {
        this.templateDetails = [];
        this.showtemplateDetails = false;
        this.loading = true;
        callAPI({
          url: `${this.$store.state.email_manager_api}/files/${value}/templates`,
          method: "GET",
          data: {}
        })
          .then(result => {
            // console.log(result)
            let promises = [];
            for (let item of result.data) {
              promises.push(this.getTemplateDetailsById(item.templateId));
            }
            this.templateDetails = [];
            Promise.all(promises).then(values => {
              this.templateDetails = values;
              //   console.log(this.templateDetails)
              this.showtemplateDetails = true;
              this.loading = false;
            });
          })
          .catch(error => {
            this.templateDetails = [];
            this.showtemplateDetails = false;
            this.loading = false;
          });
      }
    }
  },
  methods: {
    async getTemplateDetailsById(templateId) {
      try {
        const result = await callAPI({
          url: `${this.$store.state.email_manager_api}/templates/${templateId}`,
          method: "GET"
        });
        // console.log(result.data)
        return {
          templateId: result.data.templateId,
          templateName: result.data.name,
          templateDescription: result.data.description,
          templateUrl: `/admin/templates/${result.data.templateId}`
        };
      } catch (error) {
        console.log(error);
        this.errors.push(error);
      }
    },
    clickItem(item) {
      console.log("router navigation: ", item);
      this.$router.push({
        name: "Manage Template Details",
        params: { templateId: item.templateId }
      });
    }
  }
};
</script>
