<template>
  <v-container fluid class="px-8">
    <v-card flat class="mb-3">
      <v-card-title class="px-0">
        <v-btn class="white--text" small color="blue" @click="createFile">
          <v-icon left small>mdi-plus</v-icon>
          {{ $t("add") }}
        </v-btn>
      </v-card-title>
    </v-card>

    <div class="d-flex flex-row flex-row-reverse pb-3">
      {{ filesCount }} of {{ filesTotalCount }}
    </div>

    <div class="d-flex flex-row flex-wrap" v-scroll="onScroll">
      <v-card
        v-for="file in files"
        :key="file.id"
        class="mr-4 mb-6 pa-2 file-item"
      >
        <div class="d-flex flex-row mb-6">
          <div class="pa-2" style="width: 40%">
            <v-img
              :src="
                require('@/assets/file-type-icons/' +
                  getFileIcon(file.fileName) +
                  '.svg')
              "
              contain
              class="mx-auto fileManagerIcons"
            ></v-img>
            <span
              class="font-weight-thin text-center d-block mt-2"
              style="font-size: 8pt; text-transform: uppercase"
            >
              {{ file.fileName }}
            </span>
          </div>
          <div>
            <v-card-subtitle class="fileName pt-1 pb-2">
              <strong>{{ file.title }}</strong>
            </v-card-subtitle>
            <v-card-subtitle class="fileDescription pt-0 pb-2">{{
              file.description
            }}</v-card-subtitle>
            <v-card-subtitle class="fileSize pt-0 pb-4">
              <v-icon small class="mr-2">mdi-weight</v-icon>
              {{ getFileSize(file.fileSize) }}
            </v-card-subtitle>
          </div>
        </div>
        <v-card-actions style="position: absolute; bottom: 0px">
          <v-icon @click="editFile(file)" class="mr-4" color="blue"
            >mdi-pencil</v-icon
          >
          <v-icon @click="deleteFile(file)" class="mr-4" color="red"
            >mdi-delete</v-icon
          >
          <v-icon @click="downloadFile(file)" class="mr-4" color="green"
            >mdi-save</v-icon
          >
          <v-icon @click="showLinkedTemplates(file)" color="default"
            >mdi-format-list-bulleted-square</v-icon
          >
        </v-card-actions>
      </v-card>
    </div>

    <div class="d-flex flex-row flex-row-reverse pt-3">
      {{ filesCount }} of {{ filesTotalCount }}
    </div>

    <!-- create/edit file -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="modalCancel">{{
            $t("close")
          }}</v-btn>
        </v-card-actions>
        <v-card-text>
          <GsFileEditor ref="fileEditor" @saved="modalSave" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- run simulator -->
    <v-dialog v-model="linkedTemplatesVisible" max-width="300px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="linkedTemplatesVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
        <v-card-title>{{ $t("linked_templates_title") }}</v-card-title>
        <v-card-text>
          <GsLinkedTemplates :fileId="selectedFileId" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const { formatBytes } = require("@/utils/formatBytes.js");
const { fileTypeIcon } = require("@/utils/fileTypeIcon.js");
const { fileSave } = require("@/utils/fileSave.js");
import GsFileEditor from "@/components/GsFileEditor";
import GsLinkedTemplates from "@/components/GsLinkedTemplates";

const PAGE_LIMIT = 30;

export default {
  name: "Files",
  components: {
    GsFileEditor,
    GsLinkedTemplates
  },
  data() {
    return {
      files: [],
      dialog: false,
      page: 0,
      isLastPageReached: false,
      loadingFiles: false,
      filesTotalCount: 0,
      linkedTemplatesVisible: false,
      selectedFileId: null
    };
  },
  computed: {
    filesCount() {
      if (!this.files) return 0;
      return this.files.length;
    }
  },
  async created() {
    await this.getFiles();
  },
  methods: {
    async getFiles(nextPage) {
      if (nextPage) {
        if (this.isLastPageReached) return;
        this.page += 1;
      } else {
        this.page = 0;
      }

      try {
        // const url = `${this.$store.state.email_manager_api}/files?page=${this.page}&limit=${PAGE_LIMIT}&sort=fileName:asc`
        let url = `${this.$store.state.email_manager_api}/files?page=${this.page}&limit=${PAGE_LIMIT}`;

        url += `&fields=fileId,fileName,title,description,fileSize,createdAt,updatedAt,ownedBy,createdBy,updatedBy`;
        // console.log(url)

        const result = await callAPI({
          url,
          method: "GET"
        });
        this.filesTotalCount = result.headers["x-total-count"];
        this.isLastPageReached = result.data.length < PAGE_LIMIT;

        if (!nextPage) this.files = [];
        for (const file of result.data) {
          await this.calculateProperties(file);
          this.files.push(file);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.state.loading = false;
      }
    },
    async calculateProperties(file) {
      file.$calculated = file.$calculated || {};
    },
    async createFile() {
      this.dialog = true;
      this.$nextTick(async () => {
        if (!this.$refs.fileEditor) {
          return;
        }
        await this.$refs.fileEditor.create();
      });
    },
    async editFile(file) {
      this.dialog = true;
      this.$nextTick(async () => {
        if (!this.$refs.fileEditor) {
          return;
        }
        await this.$refs.fileEditor.edit(file);
      });
    },
    modalCancel() {
      this.dialog = false;
    },
    async modalSave(file) {
      this.dialog = false;
      await this.getFiles();
    },
    async deleteFile(file) {
      const result = confirm(`${this.$t("delete_modal")}\n\n${file.title}`);
      if (!result) {
        return;
      }

      const url = `${this.$store.state.email_manager_api}/files/${file.fileId}`;
      await callAPI({
        url,
        method: "DELETE"
      }).then(result => {
        if (result.status === 204) {
          this.getFiles();
        }
      });
    },
    getFileSize(fileSize) {
      return formatBytes(fileSize);
    },
    getFileIcon(fileName) {
      return fileTypeIcon(fileName);
    },
    async downloadFile(file) {
      callAPI({
        url: `${this.$store.state.email_manager_api}/files/${file.fileId}/fileContent`,
        method: "GET",
        resType: "blob"
      }).then(({ data, headers }) => {
        const fileName = headers["content-disposition"]
          ? headers["content-disposition"].match(/filename="(.*)"/)[1]
          : file.fileName;
        fileSave(data, fileName);
      });
    },
    async onPageScrolledToEnd() {
      //   console.log('onPageScrolledToEnd')
      await this.getFiles(true);
    },
    async onScroll(e) {
      // console.log("onScroll");
      if (this.loadingFiles) return;

      const el = e.target.scrollingElement;
      const distanceFromBottom = -(
        el.scrollTop +
        el.clientHeight -
        el.scrollHeight
      );
      //   console.log(distanceFromBottom)
      if (distanceFromBottom <= 0) {
        this.loadingFiles = true;
        await this.onPageScrolledToEnd();
        setTimeout(() => {
          this.loadingFiles = false;
        }, 2000);
      }
    },
    showLinkedTemplates(file) {
      this.selectedFileId = file.fileId;
      this.linkedTemplatesVisible = true;
    }
  }
};
</script>

<style lang="scss">
.comp-logo {
  width: 26px;
  height: 26px;
  display: inline-block;
}
.comp-name {
  position: relative;
  top: -8px;
}
.file-item {
  width: 18%;

  .fileName {
    height: 56px;
  }

  .fileDescription {
    height: 60px;
  }

  .fileManagerIcons {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 1400px) {
  .file-item {
    width: 23%;
  }
}
@media (max-width: 1000px) {
  .file-item {
    width: 30%;

    .fileManagerIcons {
      width: 60px;
      height: 60px;
    }
  }
}
@media (max-width: 800px) {
  .file-item {
    width: 45%;

    .fileManagerIcons {
      width: 70px;
      height: 70px;
    }
  }
}
@media (max-width: 600px) {
  .file-item {
    width: 100%;

    .fileManagerIcons {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
