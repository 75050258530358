const extensions = require("@/app-data/fileTypeIcons.json");

export function fileTypeIcon(filename) {
  const ext = filename
    ? `.${filename
        .split(".")
        .pop()
        .toLowerCase()}`
    : "";
  return extensions[ext] || "unknown";
}
