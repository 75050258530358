<template>
  <v-form ref="form">
    <v-row>
      <v-file-input
        :label="$t('file')"
        v-model="fileInfo"
        @change="fileName_change"
      ></v-file-input>
    </v-row>
    <v-row>
      <v-text-field
        :label="$t('title')"
        type="text"
        v-model="formData.title"
      ></v-text-field>
    </v-row>
    <v-row>
      <v-textarea
        :label="$t('description')"
        v-model="formData.description"
        rows="4"
        no-resize
        counter="1024"
        maxlength="1024"
      ></v-textarea>
    </v-row>
    <v-row v-if="errors != []">
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="subtitle-1 mb-4 red--text"
        >{{ error }}</span
      >
    </v-row>
    <v-row>
      <v-btn block dark class="mt-6 default-button" @click="save">{{
        $t("upload")
      }}</v-btn>
    </v-row>
  </v-form>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsFileEditor",
  props: {},
  data() {
    return {
      formData: {
        title: null,
        description: null,
        fileName: null,
        fileContent: null
      },
      fileInfo: null,
      errors: []
    };
  },
  methods: {
    async create() {
      this.clearForm();
    },
    async edit(fileId) {
      this.clearForm();
      if (typeof fileId == "string") {
        this.formData = await this.getTemplate(fileId);
      } else {
        this.formData = JSON.parse(JSON.stringify(fileId));
      }
    },
    async getFile(id) {
      try {
        const result = await callAPI({
          url: `${this.$store.state.email_manager_api}/files/${id}`,
          method: "GET"
        });
        return result.data;
      } catch (error) {
        console.log(error);
        this.errors.push(error);
      }
    },
    validation() {
      this.errors = [];
      if (!this.formData.title) {
        this.errors.push(this.$t("title"));
      }
      return this.errors.length == 0;
    },
    async save() {
      this.errors = [];
      if (!this.validation()) return;

      const url = this.formData.fileId
        ? `${this.$store.state.email_manager_api}/files/${this.formData.fileId}`
        : `${this.$store.state.email_manager_api}/files`;

      const method = this.formData.fileId ? "PATCH" : "POST";

      let data = new FormData();
      data.append("title", this.formData.title);
      if (this.formData.description) {
        data.append("description", this.formData.description);
      } else {
        data.append("description", " ");
      }
      data.append("fileName", this.formData.fileName);
      data.append("fileContent", this.formData.fileContent);

      try {
        const result = await callAPI({ url, method, data });
        this.$emit("saved", result.data);
        this.clearForm();
      } catch (error) {
        console.log(error);
        this.errors.push(error);
      }
    },
    clearForm() {
      this.formData = {
        title: null,
        description: null,
        fileName: null,
        fileContent: null
      };
      this.errors = [];
      this.fileInfo = null;
    },
    fileName_change() {
      if (!this.fileInfo) {
        this.formData.fileName = null;
        this.formData.fileContent = null;
        return;
      }
      this.formData.fileName = this.fileInfo.name;
      this.formData.fileContent = this.fileInfo;
    }
  }
};
</script>
